export default function Circle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
    >
      <circle cx="19" cy="19" r="19" fill="black" />
    </svg>
  );
}

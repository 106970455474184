import React, { useState } from "react";
import styled from "styled-components";

export default function Notice1() {
  return (
    <div>
      <br />
      <h2>제1회 소통영화제 「롱톡」 안내</h2>
      <br />
      <Text>
        <p>
          르포(REPORTAGE)가 주관하는 제1회 소통영화제 「롱톡」이 2023년 9월
          21일부터 단편영화 공모를 진행합니다. 작품 출품은 소통영화제 「롱톡」
          출품 사이트를 통해 온라인으로 신청받습니다.
        </p>
        <p>
          제1회 소통영화제 「롱톡」 출품 일정 : 2023년 9월 21일(목) ~ 2023년
          10월 15일(일) 17시까지
        </p>
        <p>
          르포(REPORTAGE)가 주관하는 제1회 소통영화제 「롱톡」이 12월 9일부터
          10일까지 양일간 개최됩니다.
        </p>
        <p>
          제1회 소통영화제 「롱톡」은 긴 글을 세 줄로 줄이고, 또 2시간을 편집해
          13분으로 재생하는 것만이 미학이 된 현 사회의 세태를 꼬집으며, 단절을
          이어붙이기 위한 첫 시작으로 현실에서 꺼내지 못하던 말들을 모아 영화의
          방식으로 이야기하고자 합니다. 릴레이소설을 모티프로 하여 사회 문제의
          여러 방면으로 조명하며 이야기되지 않던, 혹은 그래서 이야기해야만 하는
          사회의 다각을 살펴보는 창작물들을 기다리고 있습니다.
        </p>
        <p>
          선정작들은 영화제 당일 상영됩니다. 본 영화제는 단편 독립영화의 가치
          실현을 기반으로 사회문제 직시를 넘어 연결을 통한 소통을 추구합니다.
          함께 목소리를 낼 여러분의 많은 관심과 참여 부탁드립니다.
        </p>
        <br />
        <b>💬 출품작 자격 및 장르 </b> <div>다음 조건들을 만족시켜야 함.</div>
        <ul>
          <li>러닝타임: 30분 이내의 단편영화</li>
          <li>장르: 극영화, 다큐멘터리</li>
          <li>스토리 상에 사회 문제가 드러나는 영화</li>
          <li>
            다음 키워드들과 연관 지을 수 있는 영화: 소통, 조명, 연결, 이야기
          </li>
        </ul>
        <p>
          <b>💬 지원서 링크 : </b>
          <a href="https://forms.gle/YYD2PaVN6H2X7XaR8">
            https://forms.gle/YYD2PaVN6H2X7XaR8
          </a>
        </p>
        <br />
        <p>
          ☑️ 오프라인 영화제 기간 : 12월 9일(토) ~ 12월 10일(일) 각 13:00~20:00
          <br />
          ☑️ 온라인 영화제 기간 : 12월 9일(토) ~ 12월 16일(토)
          <br />
          ☑️ 접수 기간 : 9월 22일(금) ~ 10월 15일(일) 17시까지
          <br />
          ☑️ 결과 발표 : 10월 23일(월) 르포(REPORTAGE) SNS 및 온라인 영화제
          페이지 업로드 및 선정 작품 한정 출품자 개별 연락
        </p>
        <br />
        <br />
        <br />
      </Text>
    </div>
  );
}

const Text = styled.div`
  font-family: Pretendard Variable;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
`;
